import PropTypes from 'prop-types';
import { FormGroup } from '../formGroups';
import { formGroupsMapping, fieldsMapping } from './editorsMapping';
import { createPropsFromSettings } from './createPropsFromSettings';
import { EntityTemplateFieldsContext } from './Context';
import { logger } from 'utils/logs';
import useEditableTemplateFields from './useEditableTemplateFields';
import { escapeFieldName } from './helpers';
import { postCodeFieldRegex } from './postcodeValidation';
import { useSelector } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';

const EntityTemplateFields = ({ fields, applyNamesEscaping, omitInitials }) => {

  const processedFields = useEditableTemplateFields(fields);

  //Ticket 166061 Siremballage 3.22 Post code validation on the web shop
  let selectedCountryId = useSelector(state => state.user.selectedCountryId);
  const { texts: [invalidZipCodesText] } = useSanaTexts(['InvalidZipCodes']);

  return (
    <EntityTemplateFieldsContext.Provider value={processedFields}>
      {processedFields.map(({
        name: fieldName,
        title: fieldTitle,
        editor,
        settings,
        type,
        value: initialValue,
        ...formGroupProps
      }) => {
        formGroupProps = {
          fieldName: !applyNamesEscaping ? fieldName : escapeFieldName(fieldName),
          fieldTitle,
          initialValue: omitInitials || initialValue == null ? undefined : initialValue,
          ...createPropsFromSettings(settings),
          ...formGroupProps,
        };

        const FieldFormGroup = formGroupsMapping[editor];
        if (FieldFormGroup)
          return <FieldFormGroup key={fieldName} {...formGroupProps} />;

        const FieldComponent = fieldsMapping[editor];

        if (!FieldComponent) {
          logger.error(`FieldComponentGroup' or 'FieldComponent' is not found by name '${editor}' in 'editorsMapping'.`);
          return null;
        }

        //Ticket 166061 Siremballage 3.22 Post code validation on the web shop
        if (selectedCountryId === "FR") {
          let newFormGroupProps = formGroupProps;
          if (fieldName === 'Address.ZipCode' || fieldName === 'ShippingAddress.ZipCode') {
            newFormGroupProps = {
              ...newFormGroupProps,
              validation: Object.assign(newFormGroupProps.validation, { custom: value => postCodeFieldRegex(value, invalidZipCodesText) })
            }
          }
        }

        return (
          <FormGroup
            key={fieldName}
            fieldComponent={FieldComponent}
            {...formGroupProps}
          />
        );
      })}
    </EntityTemplateFieldsContext.Provider>
  );
};

EntityTemplateFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    title: PropTypes.string,
    editor: PropTypes.string.isRequired,
    settings: PropTypes.object,
  })).isRequired,
  applyNamesEscaping: PropTypes.bool,
  omitInitials: PropTypes.bool,
};

export default EntityTemplateFields;
