import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { useCountryField } from '../hooks';
import { HtmlSafeTextBoxField } from '../../fields';
import { useDispatch } from 'react-redux';
import { onCountrySelected } from '../../../../../behavior/user/actions'

const CountryDependentTextBoxField = ({
  perCountryPatterns,
  validation,
  ...props
}) => {
  const countryField = useCountryField();
  const countryFieldName = countryField && countryField.name,
    countryId = countryField && countryField.value;
  validation = { ...validation, countryDependentRegex: { perCountryPatterns, countryFieldName, countryId } };

  const { values, validateField } = useFormikContext();
  const currentCountryId = countryFieldName ? getIn(values, countryFieldName) : null;
  const dispatch = useDispatch();

  //Ticket 166061 Siremballage 3.22 Post code validation on the web shop
  useEffect(() => {
    validateField(props.fieldName);
    dispatch(onCountrySelected(currentCountryId));
  }, [currentCountryId]);

  return (
    <HtmlSafeTextBoxField
      validation={validation}
      {...props}
    />
  );
};

CountryDependentTextBoxField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  perCountryPatterns: PropTypes.array,
  validation: PropTypes.object,
};

export default CountryDependentTextBoxField;
