import { SimpleText } from 'components/sanaText';

//Ticket 166061 Siremballage 3.22 Post code validation on the web shop
export const postCodeFieldRegex = (value, invalidZipCodesText) => {
  let codePrefix = value.toString().substring(0,2);
  function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }
  let result = range(1, 95);
  let fomattedZipCodes = [];

  result.map((number) => {
    let valueStr = number.toString();
    if (number < 10)
      fomattedZipCodes.push("0" + valueStr);
    else
      fomattedZipCodes.push(number.toString());
  })

  fomattedZipCodes.push("2A");
  fomattedZipCodes.push("2B");
  const index = fomattedZipCodes.indexOf("20");
  if (index > -1) {
    fomattedZipCodes.splice(index, 1);
  }
  if (!fomattedZipCodes.includes(codePrefix)) {
    return <SimpleText textKey="InvalidZipCode_Text" />;
  }

  if (invalidZipCodesText !== undefined && invalidZipCodesText !== null && invalidZipCodesText.length > 0) {
    const splitInvalidZipCodesText = invalidZipCodesText.split(";");
    if (splitInvalidZipCodesText.length && (splitInvalidZipCodesText.find((element) => element === value) !== undefined)) {
      return <SimpleText textKey="InvalidZipCode_Text" />;
    }
  }
};